import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/layout"
import { Section } from "../../components/section"
import { ArticleMeta } from "../../components/ghost/articlemeta"
import { enhanceArticle } from "../../pages/help/cullai"
import { ArticleFeed, ArticleFeedItem } from "../../components/ghost/articlefeed"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { components } from "../../utils/mdx-components"
import SEO from "../../components/SEO/SEO"

const Article = ({ data, location, pageContext }) => {
  const { article, next, next2, prev } = data

  enhanceArticle(article)
  if (pageContext.next) {
    enhanceArticle(next)
  }
  if (pageContext.next2) {
    enhanceArticle(next2)
  }
  enhanceArticle(prev)

  var title = article.frontmatter.title
  if (article.fields.source == "help") {
    title = `${title} | ${article.frontmatter.category}`
  }

  return (
    <Layout>
      <SEO
        title={title}
        pathname={location.pathname}
        desc={article.excerpt}
        type="Article"
        article={article}
      />
      <Section nogrid noborder>

        <article className="post tag-billing-account featured single md:px-4">

          <header className="post-header container medium max-w-3xl mx-auto mb-6">
            <h1 className="text-4xl font-extrabold text-gray-900 mb-3">{article.frontmatter.title}</h1>
            {article.frontmatter.category !== 'FAQs' && (<ArticleMeta article={article}></ArticleMeta>)}
          </header>
          {article.frontmatter.media && (<div className="post-media container large max-w-xl mx-auto mb-12">
            <div className="u-placeholder">
              <a className="post-image-link" href="/downloading-printing-your-invoices/">
                <img className="post-image lazyautosizes lazyloaded w-full" data-sizes="auto" alt="Downloading/Printing Your Invoices" sizes="711px" src="https://images.unsplash.com/photo-1556740720-776b84291f8e?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjExNzczfQ" />
              </a>
            </div>
          </div>)}
          <div className="post-content kg-canvas u-text-format text-lg text-gray-500 leading-8 max-w-3xl mx-auto">
            <MDXProvider components={components}>
              <MDXRenderer>{article.body}</MDXRenderer>
            </MDXProvider>
          </div>
          <div className="post-tags container medium max-w-3xl mx-auto mt-8">
            <Link className="post-tag tag-billing-account text-xs font-bold text-gray-700 hover:text-blue-600 border px-3 py-1 rounded-full border-gray-200 hover:border-blue-600" to={article.categoryUrl}>{article.frontmatter.category}</Link>
          </div>

        </article>

      </Section>

      {pageContext.next && pageContext.next2 && (<Section nogrid noborder nopadding className="related-posts container medium" gridClassName="py-4 max-w-3xl mx-auto mb-6">
        <h3 className="related-title text-gray-600">Next articles in {article.frontmatter.category} Series...</h3>
        <ArticleFeed>

          <ArticleFeedItem article={next}></ArticleFeedItem>
          <ArticleFeedItem article={next2}></ArticleFeedItem>

        </ArticleFeed>
      </Section>)}

    </Layout>
  )
}

export default Article

export const articleQuery = graphql`
query ($id: String!, $next: String, $next2: String, $prev: String) {
  article: mdx(id: {eq: $id}) {
    body
    excerpt(pruneLength: 150)
    timeToRead
    frontmatter {
      product
      category
      title
      date(formatString: "MMM DD, YYYY")
    }
    fields {
      source
    }
  }

  next: mdx(id: {eq: $next}) {
    excerpt(pruneLength: 128)
    timeToRead
    frontmatter {
      product
      category
      title
      date(formatString: "MMM DD, YYYY")
    }
    fields {
      source
    }
  }

  next2: mdx(id: {eq: $next2}) {
    excerpt(pruneLength: 128)
    timeToRead
    frontmatter {
      product
      category
      title
      date(formatString: "MMM DD, YYYY")
    }
    fields {
      source
    }
  }

  prev: mdx(id: {eq: $prev}) {
    excerpt(pruneLength: 128)
    timeToRead
    frontmatter {
      product
      category
      title
      date(formatString: "MMM DD, YYYY")
    }
    fields {
      source
    }
  }

}
`